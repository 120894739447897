// Right now you can add 1 video per page and this code will work
// Will need to add a loop here if you want to add more than 1 video per page
const echoEmbeddedVideoClass = document.querySelector('.echo-vimeo-embedded-video-container');
if (echoEmbeddedVideoClass) {
  echoEmbeddedVideoClass.addEventListener('click', () => {
      const placeholderVideoHeight = echoEmbeddedVideoClass.clientHeight
      const placeholderVideoWidth = echoEmbeddedVideoClass.clientWidth
      // Select the iframe after it's been inserted into the DOM
      const iframe = document.querySelector('.container-lazyload > iframe');
      if (iframe) {
        // Listen for the iframe's load event
        iframe.addEventListener('load', () => {
          console.log('Iframe has loaded.');
          iframe.style.height = placeholderVideoHeight + 'px';
          iframe.style.width = placeholderVideoWidth + 'px';
        });
      } else {
        console.log('Iframe not found.');
      }
  });
}

// window.addEventListener('load', function() {
//     // Use setTimeout to delay execution by 1 second
//     setTimeout(function() {
//         const lazyLoadContainers = document.querySelectorAll('.container-lazyload');
//         // Iterate over each container
//         lazyLoadContainers.forEach(container => {
//             // Inside this div grab lazy-load-div
//             const vimeoElement = container.querySelector('.lazy-load-div');
//             if (vimeoElement) {
//                 // Capture dimensions
//                 const savedHeight = vimeoElement.clientHeight;
//                 const savedWidth = vimeoElement.clientWidth;
//                 // Add an event listener for node insertion
//                 container.addEventListener('DOMNodeInserted', function(event) {
//                     const insertedNode = event.target;
//                     if (insertedNode.tagName === 'IFRAME') {
//                         console.log('heloooooooo' + savedHeight)
//                         // Apply saved dimensions
//                         insertedNode.style.height = `${savedHeight}px`;
//                         insertedNode.style.width = `${savedWidth}px`;
//                     }
//                 });
//             }
//         });
//     }, 1000); // Execute after 1 second (1000 milliseconds)
// });
