// jQuery(document).ready(function ($) {

//     function pagination() {
//         $(document).on('click', '#posts-more', function () {
//             $btn = $(this);
//             $page_count = $btn.attr('data-page-count');
//             $post_per_page = $btn.attr('post_per_page');
//             $paged = $btn.attr('data-paged');
//             $tax = $btn.attr('data-tax');
//             $term_id = $btn.attr('data-term-id');

//             $new_page = parseInt($paged) + 1;

//             $btn.addClass('loading');

//             $.ajax({
//                 type: 'get',
//                 context: this,
//                 dataType: 'json',
//                 url: frontEndAjaxUrl.mcajaxurl,
//                 data: {
//                     action: 'pagination',
//                     post_per_page: $post_per_page,
//                     paged: $new_page,
//                     tax: $tax,
//                     term_id: $term_id,
//                 },
//                 success: function (response) {
//                     $('.posts-list').append(response.html);
//                     $btn.attr('data-paged', $new_page);
//                     $btn.removeClass('loading');

//                     if($page_count == $new_page){
//                         $('#posts-more').hide();
//                     }
//                 },

//             });
//         });
//     }
//     pagination();

//     initFixedScrollBlock();
// })

// // page init
// // jQuery(function(){
// //     initFixedScrollBlock();
// // });


// // initialize fixed blocks on scroll
// function initFixedScrollBlock() {
//     jQuery('.cp-post-content').fixedScrollBlock({
//         slideBlock: '#fixed-example1',
//         extraTop: 90
//     });
//     jQuery('.cp-post-content').fixedScrollBlock({
//         slideBlock: '#fixed-example2',
//         extraTop: 90
//         //positionType: 'absolute'
//     });
// }

// /*
//  * FixedScrollBlock
//  */
// ;(function($, window) {
//     'use strict';
//     var isMobileDevice = ('ontouchstart' in window) ||
//         (window.DocumentTouch && document instanceof DocumentTouch) ||
//         /Windows Phone/.test(navigator.userAgent);

//     function FixedScrollBlock(options) {
//         this.options = $.extend({
//             fixedActiveClass: 'fixed-position',
//             slideBlock: '[data-scroll-block]',
//             positionType: 'auto',
//             fixedOnlyIfFits: true,
//             container: null,
//             animDelay: 100,
//             animSpeed: 200,
//             extraBottom: 0,
//             extraTop: 0
//         }, options);
//         this.initStructure();
//         this.attachEvents();
//     }
//     FixedScrollBlock.prototype = {
//         initStructure: function() {
//             // find elements
//             this.win = $(window);
//             this.container = $(this.options.container);
//             this.slideBlock = this.container.find(this.options.slideBlock);

//             // detect method
//             if(this.options.positionType === 'auto') {
//                 this.options.positionType = isMobileDevice ? 'absolute' : 'fixed';
//             }
//         },
//         attachEvents: function() {
//             var self = this;

//             // bind events
//             this.onResize = function() {
//                 self.resizeHandler();
//             };
//             this.onScroll = function() {
//                 self.scrollHandler();
//             };

//             // handle events
//             this.win.on({
//                 resize: this.onResize,
//                 scroll: this.onScroll
//             });

//             // initial handler call
//             this.resizeHandler();
//         },
//         recalculateOffsets: function() {
//             var defaultOffset = this.slideBlock.offset(),
//                 defaultPosition = this.slideBlock.position(),
//                 holderOffset = this.container.offset(),
//                 windowSize = this.win.height();

//             this.data = {
//                 windowHeight: this.win.height(),
//                 windowWidth: this.win.width(),

//                 blockPositionLeft: defaultPosition.left,
//                 blockPositionTop: defaultPosition.top,

//                 blockOffsetLeft: defaultOffset.left,
//                 blockOffsetTop: defaultOffset.top,
//                 blockHeight: this.slideBlock.innerHeight(),

//                 holderOffsetLeft: holderOffset.left,
//                 holderOffsetTop: holderOffset.top,
//                 holderHeight: this.container.innerHeight()
//             };
//         },
//         isVisible: function() {
//             return this.slideBlock.prop('offsetHeight');
//         },
//         fitsInViewport: function() {
//             if(this.options.fixedOnlyIfFits && this.data) {
//                 return this.data.blockHeight + this.options.extraTop <= this.data.windowHeight;
//             } else {
//                 return true;
//             }
//         },
//         resizeHandler: function() {
//             if(this.isVisible()) {
//                 FixedScrollBlock.stickyMethods[this.options.positionType].onResize.apply(this, arguments);
//                 this.scrollHandler();
//             }
//         },
//         scrollHandler: function() {
//             if(this.isVisible()) {
//                 if(!this.data) {
//                     this.resizeHandler();
//                     return;
//                 }
//                 this.currentScrollTop = this.win.scrollTop();
//                 this.currentScrollLeft = this.win.scrollLeft();
//                 FixedScrollBlock.stickyMethods[this.options.positionType].onScroll.apply(this, arguments);
//             }
//         },
//         refresh: function() {
//             // refresh dimensions and state if needed
//             if(this.data) {
//                 this.data.holderHeight = this.container.innerHeight();
//                 this.data.blockHeight = this.slideBlock.innerHeight();
//                 this.scrollHandler();
//             }
//         },
//         destroy: function() {
//             // remove event handlers and styles
//             this.slideBlock.removeAttr('style').removeClass(this.options.fixedActiveClass);
//             this.win.off({
//                 resize: this.onResize,
//                 scroll: this.onScroll
//             });
//         }
//     };

//     // sticky methods
//     FixedScrollBlock.stickyMethods = {
//         fixed: {
//             onResize: function() {
//                 this.slideBlock.removeAttr('style');
//                 this.recalculateOffsets();
//             },
//             onScroll: function() {
//                 if(this.fitsInViewport() && this.currentScrollTop + this.options.extraTop > this.data.blockOffsetTop) {
//                     if(this.currentScrollTop + this.options.extraTop + this.data.blockHeight > this.data.holderOffsetTop + this.data.holderHeight - this.options.extraBottom) {
//                         this.slideBlock.css({
//                             position: 'absolute',
//                             top: this.data.blockPositionTop + this.data.holderHeight - this.data.blockHeight - this.options.extraBottom - (this.data.blockOffsetTop - this.data.holderOffsetTop),
//                             left: this.data.blockPositionLeft
//                         });
//                     } else {
//                         this.slideBlock.css({
//                             position: 'fixed',
//                             top: this.options.extraTop,
//                             left: this.data.blockOffsetLeft - this.currentScrollLeft
//                         });
//                     }
//                     this.slideBlock.addClass(this.options.fixedActiveClass);
//                 } else {
//                     this.slideBlock.removeClass(this.options.fixedActiveClass).removeAttr('style');
//                 }
//             }
//         },
//         absolute: {
//             onResize: function() {
//                 this.slideBlock.removeAttr('style');
//                 this.recalculateOffsets();

//                 this.slideBlock.css({
//                     position: 'absolute',
//                     top: this.data.blockPositionTop,
//                     left: this.data.blockPositionLeft
//                 });

//                 this.slideBlock.addClass(this.options.fixedActiveClass);
//             },
//             onScroll: function() {
//                 var self = this;
//                 clearTimeout(this.animTimer);
//                 this.animTimer = setTimeout(function() {
//                     var currentScrollTop = self.currentScrollTop + self.options.extraTop,
//                         initialPosition = self.data.blockPositionTop - (self.data.blockOffsetTop - self.data.holderOffsetTop),
//                         maxTopPosition =  self.data.holderHeight - self.data.blockHeight - self.options.extraBottom,
//                         currentTopPosition = initialPosition + Math.min(currentScrollTop - self.data.holderOffsetTop, maxTopPosition),
//                         calcTopPosition = self.fitsInViewport() && currentScrollTop > self.data.blockOffsetTop ? currentTopPosition : self.data.blockPositionTop;

//                     self.slideBlock.stop().animate({
//                         top: calcTopPosition
//                     }, self.options.animSpeed);
//                 }, this.options.animDelay);
//             }
//         }
//     };

//     // jQuery plugin interface
//     $.fn.fixedScrollBlock = function(options) {
//         return this.each(function() {
//             var params = $.extend({}, options, {container: this}),
//                 instance = new FixedScrollBlock(params);
//             $.data(this, 'FixedScrollBlock', instance);
//         });
//     };

//     // module exports
//     window.FixedScrollBlock = FixedScrollBlock;
// }(jQuery, this));
