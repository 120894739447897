document.addEventListener('DOMContentLoaded', function() {
  // Get all elements with the class 'editor-title'
  const editorTitles = document.querySelectorAll('.editor-title');

  editorTitles.forEach(function(editorTitle) {
      editorTitle.addEventListener('click', function() {
          const parent = editorTitle.parentElement;

          if (parent.classList.contains('editor--hide')) {
              parent.classList.remove('editor--hide');

              const contentBlock = parent.querySelector('.editor-content-block');
              const contentHeight = contentBlock ? contentBlock.offsetHeight + 40 : 0;

              const editorContent = parent.querySelector('.editor-content');
              if (editorContent) {
                  editorContent.style.maxHeight = contentHeight + 'px';
                  editorContent.style.margin = '5px 0px';
              }

              const loader = editorTitle.querySelector('.editor-title-loader');
              if (loader) {
                  loader.style.width = '100%';
              }
          } else {
              parent.classList.add('editor--hide');

              const editorContent = parent.querySelector('.editor-content');
              if (editorContent) {
                  editorContent.style.maxHeight = '0px';
                  editorContent.style.margin = '0px';
              }

              const loader = editorTitle.querySelector('.editor-title-loader');
              if (loader) {
                  loader.style.width = '0%';
              }
          }
      });
  });
});
