const categories = {
  'construction-safety': 'Construction',
  'dangerous-substances': 'COSHH',
  'falls': 'Falls',
  'featured': 'Featured',
  'first-aid': 'First Aid',
  'food': 'Food',
  'harassment': 'Harassment',
  'healthcare': 'Healthcare',
  'office-safety': 'Office Safety',
  'risk-assessment': 'Risk Assessment',
  'health-and-safety-law': 'Health & Safety Law',
  'learning-management': 'Training Staff',
  'transportation':'Transportation',
  'wellbeing': 'Wellbeing',
  'young-people': 'Young People',
}

function assignTitle(title) {
  document.querySelector('.blog-articles-container h2').innerHTML = title
}

function setElementColor(element, backgroundColor1, cssText){
  element.style.backgroundColor = backgroundColor1;
  const anchor = element.querySelector('a');
  anchor.style.cssText = cssText;
}

jQuery(document).ready(function($) {

  // Trigger AJAX call when a category link is clicked
  $('.wp-block-categories-list a').on('click', function(e) {
      e.preventDefault(); // Prevent the default link action (page refresh)

      var categoryElement = $(this).attr('href');
      var category = categoryElement.split('/category/')[1].replace('/', '');

      const urlKeyWhichUserIsOn = Object.keys(categories).find(item => category.includes(item))
      if (!urlKeyWhichUserIsOn) {
          assignTitle('Health & Safety Articles')
      } else {
        assignTitle(categories[urlKeyWhichUserIsOn])
      }
      const categoriesHtmlList = document.querySelectorAll('.blog-articles-container ul li');
      const categoriesHtmlListArray = Array.from(categoriesHtmlList);

      // Iterate over the list items
      if (categoriesHtmlListArray.length > 0) {
        const selectedCategory = categoriesHtmlListArray.find(listItem => {
          const anchor = listItem.querySelector('a'); // Find the anchor tag inside each list item
          return anchor && anchor.getAttribute('href').includes(category); // Check if the href matches the category
        });
        categoriesHtmlListArray.forEach(listItem => { // Reset selected item
          setElementColor(listItem, '#f5f5f5', 'cat-item');

        })
        if (selectedCategory) {
          setElementColor(selectedCategory, '#266DD3', 'color: white !important; background-color: #266DD3 !important');
        }
      }
      // Make an AJAX request to the server
      $.ajax({
          url: ajax_obj.ajax_url, // The AJAX URL provided by WordPress
          type: 'POST',
          data: {
              action: 'filter_articles', // Custom AJAX action
              category: category // Category slug to filter by
          },
          beforeSend: function() {
              $('.articles-list ul').html('<p>Loading articles...</p>');
          },
          success: function(response) {
              $('.articles-list ul').html(response);
          },
          error: function() {
              $('.articles-list ul').html('<p>There was an error. Please try again later.</p>');
          }
      });
  });

});
